<template>
  <div class="box-dialog">
    <el-dialog
      width="500px"
      :title="selfDetail.id ? '编辑客户成本' : '新增客户成本'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <div class="group">
        <div class="item">
          <div class="val">{{ selfDetail.ke_hu_shu_liang || 0 }}</div>
          <div class="key">客户数量(个)</div>
        </div>
        <div class="item">
          <div class="val">{{ selfDetail.ke_jun_cheng_ben || 0 }}</div>
          <div class="key">客均成本(元)</div>
        </div>
      </div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :rules="rules"
        :data.sync="selfDetail"
      />
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {},
      formConfig: {
        inline: false,
        width: '360px'
      },
      formColumn: [
        {
          type: 'date',
          title: '选择日期',
          field: 'create_date',
          required: true,
          disabledDate: 'next',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          fnChange: () => {
            this.getKeHuShuLiang()
          }
        },
        {
          type: 'text',
          title: '客户成本(元)',
          field: 'ke_hu_cheng_ben',
          required: true,
          fnChange: () => {
            this.setKeJunChengBen()
          }
        }
      ],
      rules: {
        create_date: [{ required: true, message: '请选择日期' }],
        ke_hu_cheng_ben: [{ required: true, message: '请输入客户成本' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    detail: {
      handler(val) {
        if (JSON.stringify(this.selfDetail) !== JSON.stringify(val)) {
          // 编辑时禁用日期
          if (val.id) {
            this.formColumn.forEach((item) => {
              if (item.field === 'create_date') {
                item.disabled = true
              }
            })
          }
          this.selfDetail = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 设置-客均成本
    setKeJunChengBen() {
      let { ke_hu_shu_liang, ke_hu_cheng_ben } = this.selfDetail
      let result = 0
      if (ke_hu_cheng_ben && ke_hu_shu_liang) {
        result = this.$XEUtils.divide(ke_hu_cheng_ben, ke_hu_shu_liang)
      }
      this.selfDetail.ke_jun_cheng_ben = result.toFixed(2) // 四舍五入
    },
    // 获取-客户数量
    getKeHuShuLiang() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/keHuChengBen/keHuCount',
        data: {
          date: this.selfDetail.create_date
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail.ke_hu_shu_liang = res.data.ke_hu_zong_shu
          this.setKeJunChengBen()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/keHuChengBen/saveOrUpdate',
            data: this.selfDetail
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.popupCancel()
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
  .group {
    width: 240px;
    margin: 0 0 12px 120px;
    display: flex;
    .item {
      flex: 1;
      padding: 20px 10px;
      text-align: center;
      background: @colorGrayF;
      border-radius: 4px;
      & ~ .item {
        margin-left: 10px;
      }
      .val {
        font-size: 16px;
        font-weight: bold;
        color: @colorGrayDeep;
      }
      .key {
        margin-top: 5px;
        color: @colorGrayLight;
      }
    }
  }
}
</style>
