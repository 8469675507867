<template>
  <div class="page-main">
    <CustomerTabs />
    <!-- 表格操作条 -->
    <div class="m-b-10">
      <el-button type="success" size="mini" @click="itemAdd">新增客户成本</el-button>
    </div>

    <!-- 表格 -->
    <vxe-grid ref="refTable" v-bind="tableOptions" :footer-method="footerMethod">
      <template #default_create_date="{ row }">
        {{ row.create_date ? row.create_date.slice(0, 10) : '' }}
      </template>
      <template #default_operate="{ row }">
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>
      <template #pager>
        <div></div>
        <vxe-pager v-bind="tableOptions.pagerConfig" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改客户成本弹窗 -->
    <PopupSave
      v-if="isShowPopupSave"
      :show.sync="isShowPopupSave"
      :detail.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import CustomerTabs from '../../common/customer-tabs'
import PopupSave from './popup-save'
export default {
  components: {
    CustomerTabs,
    PopupSave
  },
  data() {
    return {
      tableOptions: {
        loading: true,
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        'highlight-current-row': true,
        'show-footer': true,
        'max-height': this.$util.getViewHeight() - 187,
        pagerConfig: {
          ...this.$constant.page
        },
        columns: [
          { field: 'id', width: 100, title: '编号' },
          { field: 'create_date', title: '创建日期', slots: { default: 'default_create_date' } },
          { field: 'ke_hu_shu_liang', title: '客户数量(个)' },
          { field: 'ke_hu_cheng_ben', title: '客户成本(元)' },
          { field: 'ke_jun_cheng_ben', title: '客均成本(元)' },
          { title: '操作', width: 160, slots: { default: 'default_operate' } }
        ],
        data: []
      },
      isShowPopupSave: false,
      itemDefault: {
        id: '',
        create_date: '',
        ke_hu_shu_liang: '',
        ke_hu_cheng_ben: '',
        ke_jun_cheng_ben: ''
      },
      itemObj: {},
      totalObj: {
        sum_ke_hu_shu_liang: '',
        sum_ke_hu_cheng_ben: '',
        sum_ke_jun_cheng_ben: ''
      }
    }
  },
  mounted() {
    this.initItemObj()
    this.getListData()
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 获取列表数据
    getListData() {
      this.tableOptions.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/keHuChengBen/list',
        data: {
          sortData: {
            create_date: 'desc'
          },
          currentPage: this.tableOptions.pagerConfig.currentPage,
          pageSize: this.tableOptions.pagerConfig.pageSize
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableOptions.data = res.data.page.list
            this.tableOptions.pagerConfig.pageSize = res.data.page.pageSize
            this.tableOptions.pagerConfig.total = res.data.page.totalPage
            this.tableOptions.pagerConfig.currentPage = res.data.page.pageNumber
            this.totalObj.sum_ke_hu_shu_liang = res.data.sum_ke_hu_shu_liang
            this.totalObj.sum_ke_hu_cheng_ben = res.data.sum_ke_hu_cheng_ben
            this.totalObj.sum_ke_jun_cheng_ben = res.data.sum_ke_jun_cheng_ben
          }
        })
        .catch(() => {})
        .then(() => {
          this.tableOptions.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.tableOptions.pagerConfig.currentPage = currentPage
      this.tableOptions.pagerConfig.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'ke_hu_shu_liang') return this.totalObj.sum_ke_hu_shu_liang
          if (column.field === 'ke_hu_cheng_ben') return this.totalObj.sum_ke_hu_cheng_ben
          if (column.field === 'ke_jun_cheng_ben') return this.totalObj.sum_ke_jun_cheng_ben
        })
      ]
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupSave = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupSave = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$confirm(`创建日期：${row.create_date.slice(0, 10)}，您确认要删除该数据?`, '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/keHuChengBen/delete',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
